import ka from 'vee-validate/dist/locale/ka.json'
export default (context) => {
  return new Promise(function (resolve) {
    resolve({
      meta: {
        title: 'Tickets for concerts, shows and other events'
      },
      validation: {
        ...ka.messages,
        phone: 'ტელეფონის არასწორი ფორმატი',
        password: 'პაროლები არ მეთხვევა',
        payment_method: 'გთხოვთ, აირჩიეთ სურვილისამებრ გადახდის მეთოდი'
      },
      errors: {
        basket: {
          ticket_cant_be_reserved: 'ეს ბილეთი უკვე დაჯავშნილია'
        },
        checkout: {
          email_invalid: 'ელ.ფოსტა არასწორადაა შეყვანილი',
          email_domain_invalid: 'ელ.ფოსტის დომენი არ არსებობს',
          surname_too_short: 'გვარი ძალიან მოკლეა',
          name_too_short: 'სახელი ძალიან მოკლეა'
        },
        promo_code_not_found: 'პრომო-კოდი ვერ გააქტიურდა'
      },
      cookies: {
        content: `საიტის სწორი ფუნქციონირებისთვის საჭიროა  Cookie ფაილების გამოყენება. დეტალური ინფორმაციისთვის იხილეთ "Cookie-ს გამოყენების პოლიტიკა"
თუ რომელიმე  Cookie ფაილი მიუწვდომელია, შესაძლებელია, რომ  საიტის ზოგი სერვისის ან ფუნქციის მუშაობას შეეშალოს ხელი. მომხმარებლის უკეთესი გამოცდილებისა და მომსახურების ხარისხისთვის საიტზე გამოიყენება  Cookie ფაილების შემდეგი სახეობები: ანალიტიკური Cookie ფაილები, რომლებიც აანალიზებენ და აუმჯობესებენ საიტის ფუნქციონირებას, სოციალური Cookie მედიაფაილები, რომლებიც საშუალებას გაძლევთ გააზიაროთ კონტენტი სოციალურ ქსელებში, და ასევე მარკეტინგული Cookie ფაილები.`,
        title: `თუ გინდათ საიტით სარგებლობა განაგრძოთ, გთხოვთ, მონიშნეთ, რომ ეთანხმებით`,
        required: 'საჭირო Cookie',
        statistics: 'ანალიტიკური Cookie',
        social: 'სოციალური ქსელების Cookie ფაილები',
        marketing: 'სამიზნე (კომერციული) Cookie ფაილები'
      },
      password: {
        weak: 'სუსტი',
        fair: 'დამაკმაყოფილებელი',
        good: 'კარგი',
        strong: 'ძლიერი',
        excellent: 'ძალიან კარგი'
      },
      menuTitles: {
        concerts: 'კონცერტები',
        theater: 'თეატრები',
        festivals: 'ფესტივალები'
      },
      buttons: {
        save: 'შენახვა',
        change_password: 'პაროლის შეცვლა',
        sign_in: 'შესვლა',
        sign_up: 'რეგისტრაცია',
        load_more: 'მეტის ნახვა',
        retry_purchase: 'შენაძენის განმეორება',
        fix_world: 'სამყაროს შეცვლა სცადე',
        float_away: 'გასვლა',
        apply: 'ჩვენება',
        clear: 'გასუფთავება',
        read_more: 'Read more',
        tickets_and_more: 'Tickets & More',
        enter: 'Enter'
      },
      modal: {
        cookies_updated: 'თქვენი cookies განახლდა!',
        request_submitted: 'თქვენი მოთხოვნა წარმატებით გაიგზავნა',
        password_changed: 'პაროლი შეცვლილია!',
        personal_data_updated: 'პერსონალური მონაცემები განახლდა!'
      },
      search: {
        search: 'ძიება',
        placeholder: 'კონცერტების, დარბაზების ძებნა',
        view_all_results: 'მაჩვენე ყველა',
        no_results_found: 'ვერაფერი მოიძებნა'
      },
      headings: {
        presale_code: 'Presale code...',
        about: 'About',
        success: 'წარმატება!',
        sign_in: 'შესვლა',
        sign_in_with: 'შესვლა',
        sign_up_with: 'რეგისტრაცია',
        password_change: 'პაროლის შეცვლა',
        recommended: 'რეკომენდაციები',
        related: 'Related',
        latest: 'უახლესი',
        news: 'სიახლეები',
        rescheduled: 'გადატანილი',
        cancelled: 'გაუქმებული',
        username: 'მომხმარებლის სახელი',
        old_password: 'ძველი პაროლი',
        new_password: 'ახალი პაროლი',
        repeat_password: 'გაიმეორეთ პაროლი',
        password: 'პაროლი',
        forgotpass: 'დაგავიწყდა პაროლი',
        iagree: 'ვეთანხმები',
        name: 'სახელი',
        surname: 'გვარი',
        email: 'ელ-ფოსტა',
        phone: 'ტელეფონი',
        or: 'ან',
        newuser: 'ახალი მომხმარებელი',
        userhaveaccount: 'უკვე ხართ რეგისტრირებული',
        customersupport: 'დახმარება',
        payment: 'გადახდა',
        reset_password: 'პაროლის შეცვლა',
        reset_password_l2:
          'პაროლის შეცვლის ინსტრუქციას ელ-ფოსტაზე გამოგიგზავნით',
        categories: 'კატეგორიები',
        events: 'ღონისძიებები',
        logging_out: 'სისტემიდან გასვლა',
        payment_failed: 'გადახდა ვერ მოხერხდა',
        search_results_for: 'ძიების შედეგები'
      },
      badges: {
        soldout: 'გაყიდულია',
        premiere: 'პრემიერა'
      },
      navigation: {
        information: 'ინფორმაცია',
        faq: 'ხშირად დასმული კითხვები',
        terms: 'ბილეთების შეძენის პირობები',
        tickets: 'ბილეთები',
        etickets: 'e-ბილეთები',
        payments: 'გადახდები',
        privacy: 'Privacy policy',
        cookies: 'cookies გამოყენების პოლიტიკა',
        contacts: 'კონტაქტი',
        news: 'სიახლეები',
        partners: 'პარტნიორებისთვის',
        userprofile: 'პროფილი',
        usertickets: 'ჩემი ბილეთები',
        about: 'ჩვენს შესახებ',
        authorize: 'შესვლა',
        register: 'რეგისტრაცია',
        pay: 'გადახდა',
        apply: 'გამოყენება',
        logout: 'გასვლა',
        backToTickets: 'ბილეთებთან დაბრუნება',
        backToEventInfo: 'ღონისძიებასთან დაბრუნება',
        back: 'Back',
        gotopayment: 'გადახდაზე გადასვლა',
        retry_trans: 'ტრანზაქციის განმეორება',
        closeCart: 'კალათის დახურვა',
        remove: 'წაშლა',
        insurance: 'Insurance terms',
        publiccontract: 'Public contract'
      },
      labels: {
        company: 'კომპანიის დასახელება',
        email: 'ელ-ფოსტა',
        confirm_email: 'Confirm E-mail',
        legalAddress: 'იურიდიული მისამართი',
        name: 'სახელი',
        optional: '(არ არის აუცილებელი)',
        phone: 'ტელეფონის ნომერი',
        regnr: 'რეგ. ნომერი.',
        required: '(აუცილებელია)',
        surname: 'გვარი',
        vat: 'დღგ-ს No.'
      },
      misc: {
        support_email: 'Support email',
        tickets_left: 'Tickets available',
        choose_time: 'Choose time',
        select_your_branch: 'Select Your branch',
        back_to_all_payment_methods: 'Back to all payment methods',
        accept: 'მიღება',
        additionalOptions: 'დამატებითი პარამეტრები',
        langselector: 'ქართულად',
        withuseragreement: 'გამოყენების პირობები',
        price_from: 'დან',
        from: 'დან',
        to: 'მდე',
        price: 'ფასი',
        soldout: 'გაყიდულია',
        discount: 'ფასდაკლება',
        download: 'გადმოტვირთე',
        event_name: 'ღონისძიების დასახელება',
        loading: 'იტვირთება',
        processing_order: 'ვამუშავებთ შეკვეთას',
        load_more: 'მეტის ნახვა',
        more_info: 'More info',
        selectDiscount: 'აირჩიეთ ფასდაკლება',
        sevice_fee_and_vat: 'მომსახურების საფასური (შეიცავს დღგ-ს)',
        fixed_service_fee: 'Venue service fee (incl. VAT)',
        ticket_details: 'ინფორმაცია ბილეთზე',
        ticket_id: 'ბილეთის ID',
        ticket_no: 'ბილეთის No',
        ticket_type: 'ბილეთის სახეობა',
        tickets: 'ბილეთების | ბილეთი | ბილეთის | ბილეთების',
        total: 'სულ',
        buy_ticket: 'ბილეთის ყიდვა',
        add_to_cart: 'კალათაში დამატება',
        standticket: 'დასადგომი ადგილები',
        seatticket: 'დასაჯდომი ადგილები',
        close: 'დახურვა',
        required_fields: 'აუცილებლად შესავსები ველი',
        at: 'ში',
        no_tickets: 'ბილეთები არ არის',
        order_id: 'შეკვეთის ID',
        purchased: 'ნაყიდი',
        download_invoice: 'ინვოისის გადმოწერა',
        download_pdf: 'ჩამოტვირთვა PDF',
        not_available: 'მიუწვდომელი',
        work_hours: 'ორშ. - კვ.',
        doors: 'კარიბჭები',
        start: 'დაწყება',
        age_restriction: 'ასაკობრივი შეზღუდვა',
        tags: 'თეგები',
        by: '',
        organised_by: 'ორგანიზატორი'
      },
      ticketselector: {
        SALES_NOT_STARTED: {
          title: 'Pārdošana nav sākusies',
          subtitle: ''
        },
        SALES_ENDED: {
          title: 'Sales ended',
          subtitle: ''
        },
        AVAILABLE: {
          title: '',
          subtitle: ''
        },
        SOMEONE_IS_BUYING: {
          title: 'Someone is buying last tickets',
          subtitle: 'Maybe some tickets will be released in few minutes'
        },
        OWN: {
          title: '',
          subtitle: ''
        },
        SOLDOUT: {
          title: 'SOLDOUT',
          subtitle: ''
        }
      },
      text: {
        password_reset_email_sent:
          'პაროლის შეცვლის ინსტრუქციები გაიგზავნა მითითებულ ელ-ფოსტის მისამართზე.',
        password_has_been_changed: 'თქვენი პაროლი შეცვლილია!',
        agreeOnTerms: {
          text: 'წავიკითხე და ვეთანხმები {link}!',
          link: 'წესებს და პირობებს'
        },
        cart_is_empty: 'კალათა ცარიელია',
        enter_promo_code: 'შეიყვანეთ პრომო-კოდი',
        stripeDescription:
          'თქვენ გაქვთ 10 წუთი გადახდის დასრულებისთვის. წინააღმდეგ შემთხვევაში ტრანზაქცია გაუქმდება, თქვენს მიერ დაჯავშნილი ბილეთები შეძენისთვის ხელმისაწვდომი გახდება.',
        fdataDescription:
          'თქვენ გაქვთ 10 წუთი გადახდის დასრულებისთვის. წინააღმდეგ შემთხვევაში ტრანზაქცია გაუქმდება, თქვენს მიერ დაჯავშნილი ბილეთები შეძენისთვის ხელმისაწვდომი გახდება.',
        i_have_promocode: 'მე მაქვს პრომო-კოდი',
        promocode_activated: 'პრომო-კოდი აქტივირებულია!',
        promocode_apply_fail: 'პრომო-კოდი ვერ გააქტიურდა',
        newsAndOffers:
          'გსურთ სიახლეების და ბილეთების ხელსაყრელი შეთავაზებების მიღება?',
        purchaseAsLegal: 'შეიძინეთ როგორც იურიდიული პირი',
        swedDescription:
          '<b>ასეთი გადახდა ხელმისაწვდომია მხოლოდ Swedbank-სთვის ლატვიაში.</b> თქვენ გაქვთ 10 წუთი გადახდის დასრულებისთვის. წინააღმდეგ შემთხვევაში ტრანზაქცია გაუქმდება, თქვენს მიერ დაჯავშნილი ბილეთები შეძენისთვის ხელმისაწვდომი გახდება.',
        payment_failed_w_error: 'გადახდა ვერ მოხერხდა, შეცდომის კოდი',
        invoice_copy_sent:
          'ბილეთის და ინვოისის ასლი გამოგზავნილია თქვენს ელ-ფოსტაზე:',
        invoice_copy_will_be_sent:
          'მოკლე ხანში ბილეთები გაიგზავნება თქვენს მითითებულ ელ-ფოსტაზე.',
        user_exists_1:
          'მომხმარებელი ასეთი ელ-ფოსტით უკვე რეგისტრირებულია. გთხოვთ, ',
        user_exists_2: 'გაიარეთ ავტორიზაცია',
        user_exists_3: ' ან ',
        user_exists_4: 'აღადგინეთ პაროლი.',
        something_wrong: 'რაღაც არასწორია',
        page_not_found: 'გვერდი ვერ მოიძებნა',
        no_events_available: 'ხელმისაწვდომი ღონისძიება ვერ მოიძებნა',
        order_completed_1: 'თქვენი',
        order_completed_2: 'ზე',
        order_completed_3: 'შესრულებულია',
        processing_tickets: 'ბილეთები მუშავდება',
        choose_payment_country:
          "Please choose which country's banks / payment systems to use"
      },
      titles: {
        agreement: 'ხელშეკრულება',
        buyer: 'მყიდველი',
        paymentMethod: 'გადახდის მეთოდი',
        purchased_tickets: 'გადმოწერეთ თქვენი ბილეთები',
        your_tickets: 'თქვენი ბილეთები'
      },
      filters: {
        all_dates: 'ყველა თარიღი',
        all_categories: 'ყველა კატეგორია',
        category_selected: 'კატეგორია არჩეულია',
        pick_category: 'კატეგორიის შერჩევა',
        all_locations: 'ყველა ადგილი',
        pick_country: 'Select country',
        location_selected: 'ადგილი არჩეულია',
        pick_location: 'ადგილის არჩევა',
        pick_another_date: 'Izvēlies citu datumu'
      },
      fdata: {
        100: 'Decline (general, no comments)',
        101: 'Decline, expired card',
        102: 'Decline, suspected fraud',
        104: 'Decline, restricted card',
        105: "Decline, card acceptor call acquirer's security department",
        106: 'Decline, allowable PIN tries exceeded',
        111: 'Decline, invalid card number',
        116: 'Decline, no card record',
        118: 'Decline, not sufficient funds',
        119: 'Decline, transaction not permitted to cardholder',
        120: 'Decline, transaction not permitted to terminal',
        121: 'Decline, exceeds withdrawal amount limit',
        122: 'Decline, security violation',
        123: 'Decline, exceeds withdrawal frequency limit',
        129: 'Decline, suspected counterfeit card',
        204: 'Pick-up, restricted card',
        208: 'Pick-up, lost card',
        902: 'Decline reason message: invalid transaction',
        904: 'Decline reason message: format error',
        908: 'Decline reason message: transaction destination cannot be found for routing',
        909: 'Decline reason message: system malfunction',
        911: 'Decline reason message: card issuer timed out',
        912: 'Decline reason message: card issuer unavailable',
        940: 'Decline, blocked by fraud filter'
      },
      eventLabels: {
        postponed: 'New date is coming soon!',
        suspended: 'Sales of this event is stopped till future notice.',
        preorder:
          'Iepriekšpārdošanas sākums <b class="color--text-blue wsnw">{from}</b>',
        public_preorder:
          'Publiskās iepriekšpārdošanas sākums <b class="color--text-blue wsnw">{from}</b>'
      },
      eventLabelsButton: {
        preorder: 'Pirkt pēc {0}',
        onlinesaleended: 'Interneta tirzniecība ir izbeigta'
      },
      presale: {
        early_access: 'Early access presale',
        early_access_tooltip:
          'If you have a special presale code, you can buy tickets for this event before the public sale starts. Presale codes are distributed by the artist and/or event organizer. Ticketshop does not have these codes available.'
      },
      countdown: {
        days: 'd.',
        hours: 'h.',
        minutes: 'min.',
        seconds: 's.'
      },
      insurance: {
        available: 'Ticket insurance available',
        tickets: 'Biļešu apdrošināšana',
        title: 'ESI DROŠS UN IZVĒLIES BIĻEŠU APDROŠINĀŠANU!',
        description:
          'Tiks segti zaudējumi, ja Jums gadījusies ķibele un Jūs netiekat uz pasākumu ar veselību saistītu ierobežojumu dēļ vai pasākuma atcelšanas/pārcelšanas gadījumā, un jaunās izmaiņas Jums nav piemērotas.',
        details: 'Detalizētāk par apdrošināšanas noteikumiem',
        secure_tickets: 'Apdrošināt biļetes',
        continue_without: 'Turpināt bez apdrošināšanas',
        cost: 'APDROŠINĀŠANAS MAKSA',
        secure_order: 'Apdrošināt pirkumu',
        cancel: 'Atcelt apdrošināšanu',
        ticket_secured: 'Biļete ir apdrošināta',
        ticket_unavailable: 'დაზღვევა მიუწვდომელია',
        provider: 'BALCIA APDROŠINĀŠANA',
        info: {
          title: 'Mēs segsim zaudējumus, ja:',
          list: [
            'Tev gadījusies ķibele un Tu netiec uz pasākumu;',
            'Covid19 vai citi veselības ierobežojumi kavē plānus apmeklēt pasākumu;',
            'Pasākums tiks atcelts vai pārcelts un laiks vai vieta Tev neder.'
          ]
        }
      },
      currencyModal: {
        title: 'თქვენ გაქვთ დაუსრულებელი შეძენა {cartCurrency} ვალუტაში',
        subtitle:
          'ღონისძიება, რომელზეც გაქვთ ბილეთების არჩევანის სურვილი, არსებობს {eventCurrency} ვალუტაში. ამ მომენტში თქვენს კალათაში არსებობს ბილეთები, რომლებიც არიან ფასდებული {cartCurrency} ვალუტაში. თქვენ უნდა დაასრულოთ არსებული შეძენა ან გაასუფთოთ კალათა, რათა შეძენა შეასრულოთ {eventCurrency} ვალუტაში.',
        finish_payment: 'დაასრულოთ არსებული შეძენა {cartCurrency} ვალუტაში',
        clear_cart:
          'გაასუფთოთ კალათა და შეასრულოთ ახალი შეძენა {eventCurrency} ვალუტაში'
      },
      eventSubscribeModal: {
        title: 'Registration'
      },
      eventSubscribe: {
        registration: 'Registration',
        register: 'Register',
        earlyBird:
          'Register and get chance to purchase limited <b class="color--text-blue wsnw">Early bird tickets</b>'
      }
    })
  })
}

import lv from 'vee-validate/dist/locale/lv.json'
export default (context) => {
  return new Promise(function (resolve) {
    resolve({
      meta: {
        title: 'Koncertu, izrāžu un citu pasākumu biļetes'
      },
      validation: {
        ...lv.messages,
        phone: 'Šajā laukā jābūt pareizam tālruņa formātam',
        password: 'Paroles nesakrīt',
        payment_method: 'Lūdzu, izvēlieties vēlamo maksājuma veidu'
      },
      errors: {
        basket: {
          ticket_cant_be_reserved: 'Šo biļeti kāds jau ir norezervējis'
        },
        checkout: {
          email_invalid: 'E-pasts ievadīts nepareizi',
          email_domain_invalid: 'E-pasta domēns neekziste',
          surname_too_short: 'Uzvārds ir par īsu',
          name_too_short: 'Vārds ir par īsu'
        },
        promo_code_not_found: 'Neizdevas aktivizēt promo kodu'
      },
      cookies: {
        content: `Šī tīmekļa vietne izmanto sīkdatnes, kas ir absolūti nepieciešamas,
        lai tā darbotos pilnvērtīgi. Lai uzzinātu vairāk, lūdzu, iepazīstieties ar
        mūsu sīkdatņu lietošanas politiku. Ja nepieciešamās sīkdatnes netiks
        izmantotas, pastāv iespējamība, ka vairāki vai visi pakalpojumi un vietnes
        funkcijas var nedarboties. Lai uzlabotu lietotāja pieredzi un
        pakalpojumu kvalitāti, tīmekļa vietne izmanto šādus sīkdatņu veidus:
        analītiskās sīkdatnes, kas analizē un uzlabo vietnes darbību, sociālo
        mediju sīkdatnes, kas ļauj dalīties ar saturu sociālajos tīklos, kā
        arī mārketinga sīkdatnes.`,
        title: `Turpinot lietot tīmekļa vietni, lūdzu norādiet, vai piekrītat
        sīkdatņu izmantošanai`,
        required: 'Nepieciešamās sīkdatnes',
        statistics: 'Statistikas sīkdatnes',
        social: `Sociālo mēdiju sīkdatnes (jāatzīmē, ja vēlaties dalīties ar lapas
          saturu sociālajos tīklos)`,
        marketing: 'Mārketinga sīkdatnes'
      },
      password: {
        weak: 'Vāja',
        fair: 'Pieņemama',
        good: 'Laba',
        strong: 'Stipra',
        excellent: 'Ekselenta'
      },
      menuTitles: {
        concerts: 'Koncerti',
        theater: 'Teātris',
        festivals: 'Festivāli'
      },
      buttons: {
        save: 'Saglabāt',
        change_password: 'Nomainīt paroli',
        sign_in: 'Ienākt',
        sign_up: 'Reģistrēties',
        load_more: 'Ielādēt vairāk',
        retry_purchase: 'Mēģiniet iegādāties vēlreiz',
        fix_world: 'Mēģiniet salabot pasauli',
        float_away: 'Peldēt prom',
        apply: 'Pielietot',
        clear: 'Notīrīt',
        read_more: 'Lasīt vairāk',
        tickets_and_more: 'Biļetes un info',
        enter: 'Enter'
      },
      modal: {
        cookies_updated: 'Jūsu sīkdatnes ir atjaunotas!',
        request_submitted: 'Jūsu pieprasījums tika veiksmīgi iesniegts',
        password_changed: 'Parole mainīta!',
        personal_data_updated: 'Personas dati tika atjaunināti!'
      },
      search: {
        search: 'Meklēt',
        placeholder: 'Meklēt pasākumus un norises vietas',
        view_all_results: 'Rādīt visu',
        no_results_found: 'Netika atrasts neviens pasākums'
      },
      headings: {
        presale_code: 'Iepriekšpārdošanas kods...',
        about: 'Info',
        success: 'Vieksmīgi!',
        sign_in: 'Ienākt',
        sign_in_with: 'Ienākt ar',
        sign_up_with: 'Reģistrēties ar',
        password_change: 'Paroles maiņa',
        recommended: 'Rekomendēti',
        related: 'Saistītie pasākumi',
        latest: 'Jaunākie',
        news: 'Jaunumi',
        rescheduled: 'Pārceltie',
        cancelled: 'Atceltie',
        username: 'Lietotājvārds',
        old_password: 'Vecā parole',
        new_password: 'Jaunā parole',
        repeat_password: 'Jaunā parole atkārtoti',
        password: 'Parole',
        forgotpass: 'Aizmirsu paroli',
        iagree: 'Es piekrītu',
        name: 'Vārds',
        surname: 'Uzvārds',
        email: 'E-pasts',
        phone: 'Tālrunis',
        or: 'vai',
        newuser: 'Jauns lietotājs',
        userhaveaccount: 'Lietotājs jau eksistē',
        customersupport: 'Klientu atbalsts',
        payment: 'Apmaksa',
        reset_password: 'Paroles maiņa',
        reset_password_l2:
          'Nebaidies. Mēs nosūtīsim Tev e-pastā norādījumus paroles atiestatīšanai.',
        categories: 'Kategorijas',
        events: 'Pasākumi',
        logging_out: 'Izlogojas',
        payment_failed: 'Maksājums neizdevās',
        payment_success: 'Maksājums ir veiksmīgs!',
        search_results_for: 'Meklēšanas rezultāti'
      },
      badges: {
        soldout: 'Izpārdots',
        premiere: 'Pirmizrāde'
      },
      navigation: {
        information: 'Informācija',
        faq: 'BUJ',
        terms: 'Biļešu iegādes noteikumi',
        tickets: 'Biļetes',
        etickets: 'E-biļetes',
        payments: 'Maksājumi',
        privacy: 'Privātuma politika',
        cookies: 'Sīkdatņu izmantošanas politika',
        contacts: 'Kontakti',
        news: 'Jaunumi',
        partners: 'Partnieriem',
        about: 'Par mums',
        userprofile: 'Lietotāja profils',
        usertickets: 'Manas biļetes',
        logout: 'Iziet',
        authorize: 'Autorizācija',
        register: 'Reģistrācija',
        pay: 'Maksāt',
        apply: 'Pielietot',
        backToTickets: 'Atpakaļ uz biļetēm',
        backToEventInfo: 'Atpakaļ uz pasākumu',
        back: 'Back',
        gotopayment: 'Uz apmaksu',
        retry_trans: 'Atkārtot darījumu',
        closeCart: 'Aizvērt grozu',
        remove: 'Izņemt',
        insurance: 'Apdrošināšanas noteikumi',
        publiccontract: 'Biļešu tirdzniecības līguma vispārīgie noteikumi'
      },
      labels: {
        company: 'Uzņēmuma nosaukums',
        email: 'E-pasts',
        confirm_email: 'Confirm E-mail',
        legalAddress: 'Juridiskā adrese',
        name: 'Vārds',
        optional: '(neobligāti)',
        phone: 'Telefons',
        regnr: 'Reģ. nr.',
        required: '(nepieciešams)',
        surname: 'Uzvārds',
        vat: 'PVN Nr.'
      },
      misc: {
        support_email: 'Support email',
        tickets_left: 'Pieejamas biļetes',
        choose_time: 'Izvēlēties laiku',
        select_your_branch: 'Select Your branch',
        back_to_all_payment_methods: 'Back to all payment methods',
        accept: 'Pieņemt',
        additionalOptions: 'Papildus opcijas',
        langselector: 'Latviešu',
        withuseragreement: 'lietošanas noteikumiem',
        price_from: 'no',
        from: 'no',
        to: 'līdz',
        price: 'cena',
        soldout: 'Izpārdots',
        discount: 'Atlaide',
        download: 'Lejupielādēt',
        event_name: 'Pasākuma nosaukums',
        loading: 'Ielādējas',
        processing_order: 'Apstrādājam pasūtījumu',
        load_more: 'Ielādēt vairāk',
        more_info: 'More info',
        selectDiscount: 'Izvēlēties atlaidi',
        sevice_fee_and_vat: 'Pakalpojuma maksa (ar PVN)',
        fixed_service_fee: 'Norises vietas pakalpojuma maksa (ar PVN)',
        ticket_details: 'Biļetes informācija',
        ticket_id: 'Biļetes ID',
        ticket_no: 'Biļetes Nr.',
        ticket_type: 'Biļetes veids',
        tickets: 'biļetes | biļete | biļetes',
        total: 'Kopā',
        buy_ticket: 'Nopirkt biļeti',
        add_to_cart: 'Ielikt grozā',
        standticket: 'Stāvvietas',
        seatticket: 'Sēdvietas',
        close: 'Aizvērt',
        required_fields: 'Obligātie lauki',
        at: '',
        no_tickets: 'Nav biļešu',
        order_id: 'Pasūtījuma ID',
        purchased: 'Iegādāts',
        download_invoice: 'Lejupielādēt rēķinu',
        download_pdf: 'Lejupielādēt PDF',
        not_available: 'nav pieejams',
        work_hours: 'P. - Sv.',
        doors: 'Durvis',
        start: 'Sākums',
        age_restriction: 'Vecuma ierobežojums',
        tags: 'Tagi',
        by: 'organizē',
        organised_by: 'organizē',
        sector: 'SEK'
      },
      ticketselector: {
        SALES_NOT_STARTED: {
          title: 'Pārdošana nav sākusies',
          subtitle: ''
        },
        SALES_ENDED: {
          title: 'Sales ended',
          subtitle: ''
        },
        AVAILABLE: {
          title: '',
          subtitle: ''
        },
        SOMEONE_IS_BUYING: {
          title: 'Someone is buying last tickets',
          subtitle: 'Maybe some tickets will be released in few minutes'
        },
        OWN: {
          title: '',
          subtitle: ''
        },
        SOLDOUT: {
          title: 'SOLDOUT',
          subtitle: ''
        }
      },
      text: {
        password_reset_email_sent:
          'Instrukcijas paroles maiņai ir nosūtītas uz norādīto e-pasta adresi.',
        password_has_been_changed: 'Tava parole ir veiksmīgi nomainīta!',
        agreeOnTerms: {
          text: 'Iepazinos un piekrītu e-veikala {link}!',
          link: 'lietošanas un biļešu apdrošināšanas (p.5.7.) noteikumiem'
        },
        cart_is_empty: 'Grozs ir tukšs',
        enter_promo_code: 'Ievadiet promo kodu',
        stripeDescription:
          'Jums ir 10 minūtes, lai veiktu maksājumu. Pretējā gadījuma transakcija tiks atcelta un Jūsu izvēlētās biļetes nonāks atpakaļ tirgošanā.',
        fdataDescription:
          'Jums ir 10 minūtes, lai veiktu maksājumu. Pretējā gadījuma transakcija tiks atcelta un Jūsu izvēlētās biļetes nonāks atpakaļ tirgošanā.',
        i_have_promocode: 'Man ir promo kods',
        promocode_activated: 'Promo kods aktivizēts!',
        promocode_apply_fail: 'Neizdevas aktivizēt promo kodu',
        newsAndOffers:
          'Vai vēlies saņemt jaunumus un izdevīgus biļešu piedāvājumus?',
        purchaseAsLegal: 'Pirkums kā juridiska persona',
        swedDescription:
          '<b>Šis maksājuma veids pieejams tikai Swedbank Latvija klientiem.</b> Jums ir 10 minūtes, lai veiktu maksājumu. Pretējā gadījuma transakcija tiks atcelta un Jūsu izvēlētās biļetes nonāks atpakaļ tirgošanā.',
        payment_failed_w_error: 'Maksājums neizdevās ar kļūdas kodu',
        invoice_copy_sent:
          'Biļešu un rēķina kopija tika aizsūtīta uz Jūsu e-pastu:',
        invoice_copy_will_be_sent:
          'Pēc brīža biļetes tiks nosūtītas uz Jūsu norādīto e-pastu.',
        user_exists_1: 'Lietotājs ar šo e-pasta adresi jau eksistē. Lūdzam ',
        user_exists_2: 'ienākt',
        user_exists_3: ' vai ',
        user_exists_4: 'atjaunot paroli.',
        something_wrong: 'Kaut kas nogāja greizi',
        page_not_found: 'Lapa nav atrasta',
        no_events_available: 'Nav pieejams neviens pasākums',
        order_completed_1: 'Tavs pasūtījums',
        order_completed_2: 'uz',
        order_completed_3: 'ir izpildīts',
        processing_tickets: 'Apstrādājam biļetes',
        choose_payment_country:
          'Lūdzu, izvēlieties, kuras valsts bankas / maksājumu sistēmas izmantot.'
      },
      titles: {
        agreement: 'Vienošanās',
        buyer: 'Pircējs',
        paymentMethod: 'Apmaksas veids',
        purchased_tickets: 'Lejupielādējiet savas biļetes',
        your_tickets: 'Jūsu biļetes'
      },
      filters: {
        all_dates: 'Visi datumi',
        all_categories: 'Visas kategorijas',
        category_selected: 'kategorija izvēlēta',
        pick_category: 'Izvēlies kategoriju',
        all_locations: 'Visas norises vietas',
        pick_country: 'Select country',
        location_selected: 'vieta izvēlēta',
        pick_location: 'Izvēlies norises vietu',
        pick_another_date: 'Izvēlies citu datumu'
      },
      fdata: {
        100: 'Decline (general, no comments)',
        101: 'Decline, expired card',
        102: 'Decline, suspected fraud',
        104: 'Decline, restricted card',
        105: "Decline, card acceptor call acquirer's security department",
        106: 'Decline, allowable PIN tries exceeded',
        111: 'Decline, invalid card number',
        116: 'Decline, no card record',
        118: 'Decline, not sufficient funds',
        119: 'Decline, transaction not permitted to cardholder',
        120: 'Decline, transaction not permitted to terminal',
        121: 'Decline, exceeds withdrawal amount limit',
        122: 'Decline, security violation',
        123: 'Decline, exceeds withdrawal frequency limit',
        129: 'Decline, suspected counterfeit card',
        204: 'Pick-up, restricted card',
        208: 'Pick-up, lost card',
        902: 'Decline reason message: invalid transaction',
        904: 'Decline reason message: format error',
        908: 'Decline reason message: transaction destination cannot be found for routing',
        909: 'Decline reason message: system malfunction',
        911: 'Decline reason message: card issuer timed out',
        912: 'Decline reason message: card issuer unavailable',
        940: 'Decline, blocked by fraud filter'
      },
      eventLabels: {
        postponed: 'Jauns datums būs drīzumā!',
        suspended:
          'Šī pasākuma pārdošana ir apturēta līdz turpmākam paziņojumam.',
        preorder:
          'Iepriekšpārdošanas sākums <b class="color--text-blue wsnw">{from}</b>',
        public_preorder:
          'Publiskās iepriekšpārdošanas sākums <b class="color--text-blue wsnw">{from}</b>'
      },
      eventLabelsButton: {
        preorder: 'Pirkt pēc {0}',
        onlinesaleended: 'Interneta tirzniecība ir izbeigta'
      },
      presale: {
        early_access: 'Agrīnās piekļuves iepriekšpārdošana',
        early_access_tooltip:
          'Ja Jums ir īpašais iepriekšpārdošanas kods, biļetes uz šo pasākumu ir iespējams iegādāties pirms publiskās tirdzniecības uzsākšanas. Iepriekšpārdošanas kodus izplata mākslinieks un/vai pasākuma organizētājs. Ticketshop šie kodi nav pieejami.'
      },
      countdown: {
        days: 'd.',
        hours: 'h.',
        minutes: 'min.',
        seconds: 's.'
      },
      insurance: {
        available: 'Pieejama biļešu apdrošināšana',
        tickets: 'Biļešu apdrošināšana',
        title: 'ESI DROŠS UN IZVĒLIES BIĻEŠU APDROŠINĀŠANU!',
        description:
          'Tiks segti zaudējumi, ja Tev gadījusies ķibele vai netiec uz pasākumu ar veselību saistītu ierobežojumu dēļ, vai pasākuma atcelšanas / pārcelšanas gadījumā, ja jaunās izmaiņas Tev nemaz nav piemērotas.',
        details: 'Detalizētāk par apdrošināšanas noteikumiem',
        secure_tickets: 'Apdrošināt biļetes',
        continue_without: 'Turpināt bez apdrošināšanas',
        cost: 'APDROŠINĀŠANAS MAKSA',
        secure_order: 'Apdrošināt pirkumu',
        cancel: 'Atcelt apdrošināšanu',
        ticket_secured: 'Biļete ir apdrošināta',
        ticket_unavailable: 'Apdrošināšana nav pieejama',
        provider: 'BALCIA APDROŠINĀŠANA',
        info: {
          title: 'Mēs segsim zaudējumus, ja:',
          list: [
            'Tev ir gadījusies ķibele un Tu netiec uz pasākumu;',
            'Covid-19 vai citi veselības ierobežojumi traucē plānus apmeklēt pasākumu;',
            'Pasākums tiek atcelts vai pārcelts un jaunais laiks vai vieta Tev neder'
          ]
        }
      },
      currencyModal: {
        title: 'JUMS IR NEPABEIGTS PIRKUMS {cartCurrency} VALŪTĀ',
        subtitle:
          'Pasākums, uz kuru gribat izvēlēties biļetes ir {eventCurrency} valūtā. Šobrīd Jums grozā atrodas biļetes, kuru cena ir noteita {cartCurrency} valūtā. Jums ir jāapmaksā esošais pirkums, vai jāiztīra grozs lai veiktu pirkumu {eventCurrency} valūtā.',
        finish_payment: 'Pabeigt esošo pirkumu {cartCurrency} valūtā',
        clear_cart:
          'Iztīrīt grozu un veikt jaunu pirkumu {eventCurrency} valūtā'
      },
      eventSubscribeModal: {
        title: 'Registration'
      },
      eventSubscribe: {
        registration: 'Registration',
        register: 'Register',
        earlyBird:
          'Register and get chance to purchase limited <b class="color--text-blue wsnw">Early bird tickets</b>'
      },
      refund: {
        title: 'ATMAKSA par pirkumu Nr. <div>{number}</div>',
        details: 'Atmaksas informācija',
        deadline: 'Atmaksu var pieprasīt līdz:',
        reason: 'Atmaksas iemesls',
        info: 'Lūdzu ņemiet vērā, ka atmaksa tiek veikta uz to pašu bankas kontu vai maksājumu karti no kā tika veikts sākotnējais pirkums.',
        payment: {
          type: 'Maksājuma veids',
          bank: 'Bankas nosaukums',
          payer: 'Saņēmēja vārds',
          account: 'Konta Nr.'
        },
        order_tickets: 'Biļetes pirkumā',
        ticket_number: 'Biļetes Nr.',
        ticket_type: 'Biļetes veids',
        ticket_price: 'Cena',
        ticket_venue: 'Norises vietas maksa',
        ticket_service: 'Pakalpojuma maksa',
        flat_price: 'Biļešu neto vērtība (t.sk. PVN)',
        total_refund: 'Kopā atmaksai',
        initiate: 'Pieprasīt atmaksu',
        terms: 'Esmu izlasījis un piekrītu noteikumiem un nosacījumiem.',
        steps: {
          initiate: 'Atmaksas process uzsākts',
          process: 'Apmaksa tiek apstrādāta',
          compete: 'Atmaksa pabeigta'
        },
        success: {
          title: 'Atmaksas pieprasījums ir veiksmīgi iesniegts',
          subtitle:
            'Jūsu atmaksas pieprasījums drīzumā tiks apstrādāts. Kad atmaksa tiks apstrādāta, jūs saņemsiet atsevišķu e-pastu.'
        },
        payout: {
          title: 'Atmaksa apstrādes procesā',
          subtitle:
            'Jūsu atmaksas pieprasījums drīzumā tiks apstrādāts. Kad atmaksa tiks apstrādāta, jūs saņemsiet atsevišķu e-pastu.'
        },
        completed: {
          title: 'Atmaksa veikta',
          subtitle:
            'Atmaksa par Jūsu pieprasījumu ir veikta. Lai iegūtu sīkāku informāciju, lūdzu, skatiet atmaksas e-pastu.'
        },
        expired: {
          title: 'Atmaksas pieprasījumu termiņš pagājis',
          subtitle:
            'Šobrīd biļešu tirgotājs nav pilnvarots veikt atmaksu par šo pirkumu par cik ir pagājis noteiktais dienu skaits.'
        }
      }
    })
  })
}
